const beerOffice = process.env.VUE_APP_URL_API;

export default {
  beerOfficeURL: beerOffice,
  beerOfficeApi: beerOffice + "/api/v1",
  axiosConf: (token, formData = false) => {
    const config = {
      headers: {
        "Content-type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET",
        "Access-Control-Allow-Headers": "*",
        "cache-control": "no-cache",
        Authorization: "",
      },
    };

    if (formData) {
      config.headers["Content-type"] = "multipart/form-data; charset=utf-8";
    }

    if (token && sessionStorage.getItem("token") != null) {
      config.headers.Authorization =
        "Bearer " + sessionStorage.getItem("token");
    }
    return config;
  },
};
